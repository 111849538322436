<template>
  <v-navigation-drawer 
    permanent
    class="the-sidebar"
    floating
    app
  >
    <div class="d-flex flex-column align-center pt-8">
      <v-img
        contain
        height="100"
        width="100"
        lazy-src="@/assets/images/sceau.jpeg"
        src="@/assets/images/sceau.jpeg"
        alt="logo de la Commanderie des Templiers 2"
      />
      <the-title />
    </div>
    <menu-list />
  </v-navigation-drawer>
</template>

<script>
import MenuList from '@/components/MenuList.vue'
import TheTitle from '@/components/TheTitle.vue'

export default {
  name: 'TheSidebar',
  components: {
    MenuList,
    TheTitle
  }
}
</script>

<style scoped>
.the-sidebar {
  box-shadow: inset 0 0 10px rgb(0 0 0 / 10%);
}
</style>