<template>
  <v-list nav color="transparent">
    <v-list-item class="text-center">
      <v-list-item-title class="menu-item">
        <router-link
          class="menu-link" 
          to="/home"
        >
          Accueil
        </router-link>
      </v-list-item-title>
    </v-list-item>
    <v-menu :offset-x="offset">
      <template #activator="{ on }" >
        <div 
          class="menu-item d-flex align-center justify-center my-5"
          v-on="on"
        >
            <span class="menu-link">Vie Locale</span>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, i) in subListVieLocale"
          :key="i"
          link
          active-class="active-item-custom"
        >
          <v-list-item-title class="menu-item">
            <router-link
              class="menu-link" 
              :to="item.path"
            >
              {{ item.name }}
            </router-link>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-list-item 
      v-for="(item, i) in itemsList" 
      :key="i"
      class="text-center"
    >
      <v-list-item-title class="menu-item">
        <router-link
          class="menu-link" 
          v-if="item.path"
          :to="item.path"
        >
          {{ item.name }}
        </router-link>
        <a 
          v-else 
          class="menu-link" 
          :href="item.link"
          target="_blank"
        >
            {{ item.name }}
        </a>
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "MenuList",
  data() {
    return {
      offset: true,
      isActive: false,
      currentItem: 1,
      itemsList: [
        {
          name: 'Infos pratiques',
          path: '/infos-pratiques'
        },
        {
          name: 'A propos de l\'AFUL',
          path: '/a-propos'
        },
        {
          name: 'Espace membres',
          link: 'https://membre.aful-commanderie2.fr/'
        },
        {
          name: 'Nous contacter',
          path: '/contact'
        }
      ],
      subListVieLocale: [
        {
          name: 'Commerces & marchés',
          path: '/commerces'
        },
        {
          name: 'Ecoles & jeunesse',
          path: '/ecoles-jeunesse'
        },
        {
          name: 'Transports',
          path: '/transports'
        },
        {
          name: 'Loisirs & sports',
          path: '/loisirs-sports'
        },
        {
          name: 'Gestion des déchets',
          path: '/gestion-des-dechets'
        }
      ]
    }
  }
}
</script>

<style scoped>
.menu-item {
  list-style:none;
  margin: 15px 0px;
  width: 100%;
  height: auto;
}
.menu-link {
  text-decoration: none !important;
  color: #444444;
  line-height: 22px;
  font-family: source sans pro, sans-serif !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 3px;
  cursor: pointer;
}
.menu-link:hover {
  color: #1abc9c;
}
.router-link-active {
  color: #1abc9c !important;
  background: transparent !important;
}
.v-list-item--link:before {
  background-color: transparent !important;
}
.active-item-custom {
  background: transparent !important;
  color: #1abc9c !important;
}
</style>