<template>
  <div>
    <v-app-bar 
      app 
      color="white" 
      height="90" 
      class="px-6" 
      dark
    >
      <v-app-bar-nav-icon @click="activeDrawer=true">
        <v-icon 
          size="35"
          color="black"
        > 
          mdi-menu 
        </v-icon>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <img
        lazy-src="@/assets/images/sceau.jpeg"
        src="@/assets/images/sceau.jpeg"
        alt="logo de la Commanderie des Templiers 2"
        contain
        height="50"
        width="auto"
      />
    </v-app-bar>
      <v-navigation-drawer 
        v-model="activeDrawer" 
        app 
        temporary
        class="nav-menu"
      >
        <the-title />
        <menu-list />
      </v-navigation-drawer>
  </div>
</template>

<script>
import MenuList from '@/components/MenuList.vue'
import TheTitle from '@/components/TheTitle.vue'

export default {
  name: "TheMenuMobile",
  components: {
    MenuList,
    TheTitle
  },
  data() {
    return {
      selected: null,
      activeDrawer: false,
      group: null,
    }
  }
}
</script>

<style scoped>
.the-title {
  font-family: 'UnifrakturMaguntia', cursive;
  margin: 3rem 0;
}
.nav-menu {
  z-index: 1000;
}
</style>