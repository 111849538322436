<template>
  <h1 class="text-center the-title d-flex flex-column">
    <span>La Commanderie</span>
    <span>des Templiers 2</span>
  </h1>
</template>

<script>
export default {
  name: "TheTitle",
};
</script>

<style scoped>
.the-title {
  font-family: "UnifrakturMaguntia", cursive;
  margin: 3rem 0;
}
</style>