<template>
  <v-row 
    no-gutters 
    class="cass-layout-default"
  >
    <the-sidebar v-if="$vuetify.breakpoint.mdAndUp"/>
    <the-menu-mobile v-else />
    <v-col class="pa-0">
      <v-container class="container-principal pa-0 ma-0">
        <slot />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import TheSidebar from '@/components/TheSidebar.vue'
import TheMenuMobile from '@/components/TheMenuMobile.vue'

export default {
  name: 'LayoutDefault',
  components: {
    TheSidebar,
    TheMenuMobile
  },
}
</script>

<style lang="scss" scoped>
.container-principal {
  background-color:#F9F9F9;
  height: 100% !important;
  min-height: 100vh;
  max-width: 100% !important;
}
</style>